<template>
  <div class="home_page">
    <section class="header">
      <div class="left">
        <img class="logo" src="../../../assets/images/homePage/logo.png" alt="" />
        <i class=""></i>
        <span class="slogan">金融视频营销服务商</span>
      </div>
      <ul class="nav">
        <li class="nav_item"><router-link to="/homePage/index">首页</router-link></li>
        <li class="nav_item"><router-link to="/homePage/product">产品</router-link></li>
        <li class="nav_item"><router-link to="/homePage/aboutUs">关于我们</router-link></li>
      </ul>
      <div class="right" @click="goLogin">{{ visible ? "注册/登录" : "进入工作台" }}</div>
    </section>
    <section class="container">
      <router-view />
    </section>
    <section class="footer">
      <ul class="part_contain_box">
        <li>
          <strong>联系我们</strong><br />
          <span>
            商务合作：guozhiyuan@dengtacj.com<br />
            电话：17786576971 <br />
            地址：武汉市洪山区光谷大道70号现代 <br />
            光谷世贸中心B栋15楼
          </span>
        </li>
        <li>
          <strong>服务与支持</strong><br />
          <span><router-link to="/homePage/update">更新日志</router-link></span
          ><br />
          <span><router-link to="/homePage/protocol">服务条款</router-link></span>
        </li>
        <li>
          <strong>友情链接</strong><br />
          <span>
            <a href="https://tanzhiying.com/index" target="_blank">弹指营</a> <br />
            <a href="https://dock.tanzhiw.com/index" target="_blank">DOCK分发</a><br />
          </span>
        </li>
        <li>
          <strong>微信公众号</strong><br />
          <img src="../../../assets/images/homePage/wxgzh.png" alt="" />
        </li>
      </ul>
      <div class="web_record"><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备17015359号-6</a></div>
    </section>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      visible: localStorage.loginInfo === undefined || !localStorage.loginInfo ? true : false,
    };
  },
  methods: {
    goLogin() {
      if (this.visible) {
        this.$router.push({ path: "/login" });
      } else {
        this.$router.push({ path: "/home" });
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "./layout.less";
</style>
